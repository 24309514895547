import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { MenuHeader, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChemiCharacterItem, ChemiImgBox, ChemiItem, ChemiListTap, ReportInfoSection } from "component/report/items";
import { HeaderTap } from "component/basic/tap";
import { basicImgSet, comFormat, nightTimeChk } from "js/function";
import { SelPopup } from "component/basic/popup";

const Chemi = (props) => {
    const navigate = useNavigate();
    const selId = sessionStorage.getItem("chemiId") && sessionStorage.getItem("chemiId") !== "null" && sessionStorage.getItem("chemiId") !== "undefined" ? sessionStorage.getItem("chemiId") : null;
    
    const [barcode,setBarcode] = useState("");
    const [tap,setTap] = useState(1);
    const [tapType,setTapType] = useState("chemi");

    const [btnAddClass,setBtnAddClass] = useState("");

    const [selPopupData,setSelPopupData] =  useState(null);
    
    const familyApi = useGet({
        url:localStorage.getItem("token") ? "/family" : null,
        loginType:"login"
    });
    const familyData = familyApi?.data;

    const camiApi = useGet({
        url:localStorage.getItem("token") ? `/family/cami?barcode_id=${barcode}` : null,
        loginType:"login"
    });
    const myData = camiApi?.data?.my_info;
    const camiData = camiApi?.data?.cami;

    const camiListApi = useGet({
        url:localStorage.getItem("token") ? `/family/cami/tab?barcode_id=${barcode}&tab=${tap}` : null,
        loginType:"login"
    });
    const camiListData = barcode !== "" && myData && camiData ? camiListApi?.data?.cami : [
        {
          "category_name": tap == 1 ? "Skin" : "Antioxidants",
          "cami": tap == 1 ? "Got that natural skin glow" : "Time controller versus one chased by time"
        },
        {
          "category_name": tap == 1 ? "Hair" : "Minerals",
          "cami": tap == 1 ? "Flawlessly filled to the brim" : "Windbreakers and candles against the wind"
        },
        {
          "category_name": tap == 1 ? "Lifestyle Habits" : "Blood Circulation",
          "cami": tap == 1 ? "Sharing the same room, taking care of each other" : "It's good to move separately after eating."
        },
        {
          "category_name": tap == 1 ? "Bone health" : "Metabolic Capacity",
          "cami": tap == 1 ? "Mindful of broken bones, no unnecessary strain" : "The exciting timing is the same"
        },
        {
          "category_name": tap == 1 ? "Exercise" : "Dependency",
          "cami": tap == 1 ? "We have good chemistry, Masters of exercise" : "At least not doing it at home!"
        },
        {
          "category_name": tap == 1 ? "Vitamins" : "Sleep",
          "cami": tap == 1 ? "Taking vitamins as a duo" : "The sun and the moon"
        },
        {
          "category_name": tap == 1 ? "Eye Health" : "Eating",
          "cami": tap == 1 ? "Twin telescopes spotting tiny stars" : "A light eater and a hearty eater"
        },
        {
          "category_name": tap == 1 ? "Obesity" : "Taste",
          "cami": tap == 1 ? "Lifelong diet buddies" : "There was a reason for picky eater"
        }
    ];

    const [familyList,setFamilyList] = useState([]);
    const [familyBarcode,setFamilyBarcode] = useState([]);

    function tapCh(id){
        if(id == "report"){
            navigate("/report");
        }
    }

    //플로팅 버튼 변경 이벤트
    function handleScroll(){
        let scrollTop = window.scrollY;
        if(scrollTop > 0){
            setBtnAddClass("active")
        }else{
            setBtnAddClass("")
        }
    }

    //캐미 대상 선택 팝업 항목
    function chemiSelItem(img,name){
        return `<div class="chemiSelItem"><div class="chemiSelItemImg bgImgItem" style="background-image:url('${img}')"></div><span class="notranslate">${name}</span></div>`
    }

    useEffect(() => {
        handleScroll();
        const scrollEv = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(scrollEv);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        let settingChk = true;
        let familyListArr = [],
            familyBarcodeArr = [];
        let spouse_babyChk = false,
            my_babyChk = false;

        //비교대상 세팅
        if(selId){
            setBarcode(selId)

            sessionStorage.setItem("chemiId", selId)
        }else if(familyData?.spouse_info?.resultType == 1){
            setBarcode(familyData?.spouse_info?.barcode_id)
        }else if(familyData?.my_baby_barcodes && familyData?.my_baby_barcodes.length > 0){
            familyData?.my_baby_barcodes.forEach(function(item,i){
                if(item.resultType == 1 && settingChk){
                    settingChk = false;
                    setBarcode(item?.barcode_id)
                }
            })
        }else if(familyData?.spouse_baby_barcodes && familyData?.spouse_baby_barcodes.length > 0){
            familyData?.spouse_baby_barcodes.forEach(function(item,i){
                if(item.resultType == 1 && settingChk){
                    settingChk = false;
                    setBarcode(item?.barcode_id)
                }
            })
        }

        //배우자 정보
        if(familyData?.spouse_info){
            if(familyData?.spouse_info?.resultType == 1){
                familyListArr.push(chemiSelItem(nightTimeChk() ? basicImgSet(familyData?.spouse_info?.character_image_night||null) : basicImgSet(familyData?.spouse_info?.character_image||null),familyData?.spouse_info?.nickname));
                familyBarcodeArr.push(familyData?.spouse_info?.barcode_id);
            }
            if(familyData?.my_baby_barcodes.length <= 0 && familyData?.spouse_baby_barcodes.length <= 0){
                setFamilyList(familyListArr);
                setFamilyBarcode(familyBarcodeArr);
            }
        }

        //내 자식 정보
        if(familyData?.my_baby_barcodes){
            if(familyData?.my_baby_barcodes.length > 0){
                familyData?.my_baby_barcodes.forEach(function(item,i){
                    if(item.resultType == 1){
                        familyListArr.push(chemiSelItem(nightTimeChk() ? basicImgSet(item?.character_image_night||null) : basicImgSet(item?.character_image||null),item?.nickname));
                        familyBarcodeArr.push(item?.barcode_id);
                    }
                    if(familyData?.my_baby_barcodes.length - 1 == i){
                        my_babyChk = true;
                        if(my_babyChk && spouse_babyChk){
                            setFamilyList(familyListArr);
                            setFamilyBarcode(familyBarcodeArr);
                        }
                    }
                })
            }else{
                my_babyChk = true;
                if(my_babyChk && spouse_babyChk){
                    setFamilyList(familyListArr);
                    setFamilyBarcode(familyBarcodeArr);
                }
            }
        }

        //배우자 자식 정보
        if(familyData?.spouse_baby_barcodes){
            if(familyData?.spouse_baby_barcodes.length > 0){
                familyData?.spouse_baby_barcodes.forEach(function(item,i){
                    if(item.resultType == 1){
                        familyListArr.push(chemiSelItem(nightTimeChk() ? basicImgSet(item?.character_image_night||null) : basicImgSet(item?.character_image||null),item?.nickname));
                        familyBarcodeArr.push(item?.barcode_id);
                    }
                    if(familyData?.spouse_baby_barcodes.length - 1 == i){
                        spouse_babyChk = true;
                        if(my_babyChk && spouse_babyChk){
                            setFamilyList(familyListArr);
                            setFamilyBarcode(familyBarcodeArr);
                        }
                    }
                })
            }else{
                spouse_babyChk = true;
                if(my_babyChk && spouse_babyChk){
                    setFamilyList(familyListArr);
                    setFamilyBarcode(familyBarcodeArr);
                }
            }
        }
    }, [familyData]);

    return (
        <PageSizing addClass="">
            <MenuHeader
                languageCh={(type)=>{props.languageCh(type)}}
                language={props.language}
            />
            <HeaderTap
                data={[
                    {
                        id:"report",
                        category:"<img src='/assets/images/img/report_tap_off.svg'/>My DNA"
                    },
                    {
                        id:"chemi",
                        category:"<img src='/assets/images/img/dna_tap_on.svg'/>Genetic Chemistry"
                    }
                ]}
                val={tapType}
                func={(id) => {tapCh(id)}}
            />
            <ReportInfoSection color="rgba(222, 252, 236, 0.5)">
                {barcode !== "" && myData && camiData ? 
                <div className="chemi_characterBox">
                    <ChemiCharacterItem
                        addClass="myChemiItem"
                        func={()=>{}}
                        img={
                            nightTimeChk() ? basicImgSet(myData?.character_image_night||null)
                             : basicImgSet(myData?.character_image||null)
                        }
                        icon="/assets/images/img/me_icon.svg"
                        name={myData?.nickname}
                    />
                    <ChemiCharacterItem
                        addClass=""
                        func={()=>{
                            setSelPopupData({
                                title:"Select Genetic Chemistry Subject",
                                addClass:"selBgType",
                                closeType:true,
                                items:familyList,
                                val:familyBarcode,
                                sel:barcode,
                                notranslate:true,
                                closeFunc:(e) => {},
                                func:(e) => {
                                    setBarcode(e.val);
                                    sessionStorage.setItem("chemiId", e.val);
                                }
                            })
                        }}
                        img={
                            nightTimeChk() ? basicImgSet(camiData?.character_image_night||null)
                             : basicImgSet(camiData?.character_image||null)
                        }
                        icon="/assets/images/img/sub_report.svg"
                        name={camiData?.nickname}
                    />
                </div>
                :
                <div className="chemi_characterSample">
                    <h1 className="chemi_characterSample_title">How similar are we?</h1>
                    <button type="button" className="chemi_characterSample_link" onClick={()=>{navigate("/report/family")}}>Select Subject</button>
                </div>
                }
                <ChemiImgBox>
                    <h1 className="chemi_imgTitle">
                        <b>
                            {barcode !== "" && myData && camiData ? 
                                <i className={"notranslate"}>{myData?.nickname}</i>
                                :
                                <i className={""}>mom</i>
                            }
                            <i className="notranslate"> </i><i className="notranslate">{props.language == "en" ? "and" : "dan"}</i><i className="notranslate"> </i>
                            {barcode !== "" && myData && camiData ? 
                                <i className={"notranslate"}>{camiData?.nickname}</i>
                                :
                                <i className={""}>baby</i>
                            }
                        </b><br/>
                        <b>{barcode !== "" && myData && camiData ? <><i>are</i><i class="notranslate"> </i><i class="notranslate">"{props.language == "en" ? camiApi?.data?.title : camiApi?.data?.title_id ? camiApi?.data?.title_id : camiApi?.data?.title}"</i></> : <><i>are</i><i class="notranslate"> </i><i class="notranslate">"{props.language == "en" ? 'copy and paste' : 'Duplikat'}"</i></>}</b><br/>
                        <span className="chemiCountText">
                            <i className="notranslate">[</i>
                            {barcode !== "" && myData && camiData ? 
                                <i className="notranslate">{comFormat(camiData?.count)}</i>
                                :
                                <i className="notranslate">{comFormat(53)}</i>
                            }
                            <i class="notranslate"> </i>
                            <i>genetic traits</i>
                            <i className="notranslate">]</i>
                        </span>
                    </h1>
                    <img src={barcode !== "" && myData && camiData ? camiApi?.data?.image : "/assets/images/img/chemi_sample.png"}/>
                    <p className={`chemi_imgCount notranslate`}>{barcode !== "" && myData && camiData ? props.language == "en" ? camiApi?.data?.text : camiApi?.data?.text_id : props.language == "en" ? "A carbon copy of each other!" : "Buah tidak jatuh, jauh dari pohonnya! Mirip!"}</p>
                </ChemiImgBox>
            </ReportInfoSection>
            <div className="chemiList">
                <h1 className="chemiList_title">See our chemistry</h1>
                <div className="chemiList_typeBox">
                    <ChemiListTap
                        id={1}
                        val={tap}
                        func={(id)=>{setTap(id)}}
                        categoryName="Matches well"
                    />
                    <ChemiListTap
                        id={0}
                        val={tap}
                        func={(id)=>{setTap(id)}}
                        categoryName="Doesn't match"
                    />
                </div>
                <div className="chemiListBox">
                    {barcode !== "" && myData && camiData ? "" : 
                        <div className="reportDataCover">
                            <img src="/assets/images/report/not_report.svg"/>
                            <p className="reportDataCover_text">This section cannot be viewed yet</p>
                        </div>
                    }
                    {camiListData && camiListData?.map((item,i)=>(
                        <ChemiItem
                            key={i}
                            func={()=>{if(barcode !== "" && myData && camiData)navigate(`/report/chemi/detail/${barcode}/${item.category_name}`)}}
                            category_name={item.category_name}
                            cami={item.cami}
                            category_name_id={item.category_name_id}
                            cami_id={item.cami_id}
                            language={props.language}
                            type={tap}
                        />
                    ))}
                </div>
            </div>
            {barcode !== "" && myData && camiData ? 
            <button type="button" onClick={()=>{navigate("/report/family")}} className={`link_familyAdd ${btnAddClass}`}>
                <img src="/assets/images/basic/add_big.svg"/>
                <p className="link_familyAddText">Manage Family</p>
            </button>:""}
            <SelPopup
                data={selPopupData}
            />
        </PageSizing>
    );
};

export default Chemi;